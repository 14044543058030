#root{
  height: 100%;
}

html, body{height: 100%;}

body {
  margin: 0;
  font-family: 'Proxima Nova';
  background-color: #F7F8FA;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

@font-face {
  font-family: 'Proxima Nova';
  src: local('Proxima Nova'), url('../src/assets/fonts/proximaNova/ProximaNova-Thin.ttf');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: local('Proxima Nova'), url('../src/assets/fonts/proximaNova/ProximaNova-Light.ttf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: local('Proxima Nova'), url('../src/assets/fonts/proximaNova/ProximaNova-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: local('Proxima Nova'), url('../src/assets/fonts/proximaNova/ProximaNova-Semibold.ttf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: local('Proxima Nova'), url('../src/assets/fonts/proximaNova/ProximaNova-Bold.ttf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: local('Proxima Nova'), url('../src/assets/fonts/proximaNova/ProximaNova-Extrabld.ttf');
  font-weight: 700;
  font-style: normal;
}

:root {
  --color-gray: rgb(97,97,97);
  --color-red: #FF2608;
  --pane-padding: 5px 42px;
}